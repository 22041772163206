.custom-quill-container {
    min-height: 250px;
    width: 100%;
}
.custom-quill .ql-toolbar{
    height: 48px;
}
.reply{
    min-height: fit-content;
}
.custom-quill.ql-editor {
    min-height:225px;
}
.ql-container.ql-snow{
    border: none;
}
.ql-toolbar.ql-snow {
    border-left: none;
    border-right: none;
}